
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { addApolloState, initializeApollo } from 'content-service';
import { naumaiFooterQuery, naumaiHeaderQuery } from 'content-service/src/helpers';
import { GetStaticProps } from 'next';
import { CONTENT_SERVICE } from 'src/constants';
import { useTrackErrorPageViews } from 'tracking/tealium/views/error';
import { Error } from 'ui';
const Naumai404Page = ({ preview }: {
    preview: boolean;
}) => {
    useTrackErrorPageViews();
    return <Error preview={preview} statusCode={404}/>;
};
const getStaticProps: GetStaticProps = async ({ locale, preview = false }) => {
    const apolloClient = initializeApollo({ preview: preview });
    try {
        const response = await Promise.allSettled([
            naumaiHeaderQuery({ apolloClient, codeId: CONTENT_SERVICE.HEADER_CODE_ID, preview, locale }),
            naumaiFooterQuery({ apolloClient, preview, locale }),
        ]);
        response.filter((queryResponse) => {
            if (queryResponse.status !== 'fulfilled')
                console.error(`⚠️  Error in query response for 404 page`);
        });
        return addApolloState(apolloClient, {
            props: { preview, locale }
        });
    }
    catch (error) {
        return addApolloState(apolloClient, {
            props: { preview, pageError: true, locale }
        });
    }
};
export default Naumai404Page;

    async function __Next_Translate__getStaticProps__195c5d95ae4__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195c5d95ae4__ as getStaticProps }
  